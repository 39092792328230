import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="combat"></a><h2>Combat</h2>
    <a id="special-initiative-actions"></a><h3>SPECIAL INITIATIVE ACTIONS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial">Here are ways to change when you act during
combat by altering your place in the initiative order.</p>
    <h5><a id="delay"></a>DELAY</h5>
    <p className="initial">By choosing to delay, you take no action and
then act normally on whatever initiative count you decide to act. When
you delay, you voluntarily reduce your own initiative result for the
rest of the combat. When your new, lower initiative count comes up
later in the same round, you can act normally. You can specify this new
initiative result or just wait until some time later in the round and
act then, thus fixing your new initiative count at that point.</p>
    <p>You never get back the time you spend waiting to see what&#8217;s
going to happen. You can&#8217;t, however, interrupt anyone else&#8217;s action (as
you can with a readied action).</p>
    <a id="initiative-consequences-of-delaying"></a><p><b>Initiative Consequences of Delaying</b>: Your initiative
result becomes the count on which you took the delayed action. If you
come to your next action and have not yet performed an action, you
don&#8217;t get to take a delayed action (though you can delay again). If you
take a delayed action in the next round, before your regular turn comes
up, your initiative count rises to that new point in the order of
battle, and you do not get your regular action that round.</p>
    <h5><a id="ready"></a>READY</h5>
    <p className="initial">The ready action lets you prepare to take an
action later, after your turn is over but before your next one has
begun. Readying is a standard action. It does not provoke an attack of
opportunity (though the action that you ready might do so).</p>
    <a id="readying-and-action"></a><p><b>Readying an Action</b>: You can ready a standard action, a
move action, or a free action. To do so, specify the action you will
take and the conditions under which you will take it. Then, any time
before your next action, you may take the readied action in response to
that condition. The action occurs just before the action that triggers
it. If the triggered action is part of another character&#8217;s activities,
you interrupt the other character. Assuming he is still capable of
doing so, he continues his actions once you complete your readied
action. Your initiative result changes. For the rest of the encounter,
your initiative result is the count on which you took the readied
action, and you act immediately ahead of the character whose action
triggered your readied action.</p>
    <p>You can take a 5-foot step as part of your readied action, but
only if you don&#8217;t otherwise move any distance during the round. </p>
    <a id="initiative-consequences-of-readying"></a><p><b>Initiative Consequences of Readying</b>: Your initiative
result becomes the count on which you took the readied action. If you
come to your next action and have not yet performed your readied
action, you don&#8217;t get to take the readied action (though you can ready
the same action again). If you take your readied action in the next
round, before your regular turn comes up, your initiative count rises
to that new point in the order of battle, and you do not get your
regular action that round.</p>
    <a id="distracting-spellcasters"></a><p><b>Distracting Spellcasters</b>: You can ready an attack
against a spellcaster with the trigger &#8220;if she starts casting a spell.&#8221;
If you damage the spellcaster, she may lose the spell she was trying to
cast (as determined by her Concentration check result).</p>
    <a id="readying-to-counterspell"></a><p><b>Readying to Counterspell</b>: You may ready a counterspell
against a spellcaster (often with the trigger &#8220;if she starts casting a
spell&#8221;). In this case, when the spellcaster starts a spell, you get a
chance to identify it with a Spellcraft check (DC 15 + spell level). If
you do, and if you can cast that same spell (are able to cast it and
have it prepared, if you prepare spells), you can cast the spell as a
counterspell and automatically ruin the other spellcaster&#8217;s spell.
Counterspelling works even if one spell is divine and the other arcane.</p>
    <p>A spellcaster can use dispel magic to counterspell another
spellcaster, but it doesn&#8217;t always work.</p>
    <a id="readying-a-weapon-against-a-charge"></a><p><b>Readying a Weapon against a Charge</b>: You can ready
certain piercing weapons, setting them to receive charges. A readied
weapon of this type deals double damage if you score a hit with it
against a charging character.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      